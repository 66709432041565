@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";
.main_bg {
    background: rgb(242, 242, 242);
    background: linear-gradient( 95deg, #000000 0%, #e6ffff 50%);
}

.btn_primary {
    background: rgb(183, 92, 255);
    background: linear-gradient( 193deg, rgba(183, 92, 255, 1) 15%, rgba(103, 26, 228, 1) 89%);
}

.btn_primary_outline {
    background: transparent;
    border: 3px solid linear-gradient( 193deg, rgba(183, 92, 255, 1) 15%, rgba(103, 26, 228, 1) 89%);
}